import dataImage from "../assets/images/data.svg"
import accountingImage from "../assets/images/accounting.svg"
import ideaImage from "../assets/images/idea.svg"
import moneyImage from "../assets/images/money.svg"
import magnetImage from "../assets/images/magnet.svg"
import emailImage from "../assets/images/email.svg"

const services = [{
        id: 1,
        name: "השקעות",
        avatar: dataImage,
        short: `
      בחינת תיק הנכסים והצגת אפיקי ההשקעה והמכשירים הפיננסיים המתאימים
    `,
        info: `
      במסגרת שירות זה אנו בוחנים את תיק הנכסים של לקוחותינו
      ומציגים להם את אפיקי ההשקעה והמכשירים הפיננסים המתאימים לפרופיל
      הסיכון המועדף עליהם תוך הוזלת דמי הניהול של ההשקעות, פיזור הסיכונים,
      שילוב של השקעות אלטרנטיביות, שילוב של אפיקים בעל יתרונות מיסוי ועוד.
    `,
    },
    {
        id: 2,
        name: "פרישה",
        avatar: magnetImage,
        short: `
      סיוע למיצוי כל הזכויות מאפיקי החיסכון הפנסיוני ותכנון הקצבה החודשית
    `,
        info: `
      במסגרת שירות זה אנו מסייעים למצות את כל הזכויות מאפיקי החיסכון הפנסיוני, תוך
      הימנעות מקסימלית מתשלום מס, ומסייעים לתכנן את הקצבה החודשית
      בהתאם לאפשרויות הקיימות במסגרת החסכונות הפנסיוניים של הלקוח.
    `,
    },

    {
        id: 3,
        name: "מיסוי",
        avatar: ideaImage,
        short: `
    תכנון מס אופטימאלי על אפיקי השקעה פנסיוניים ופיננסיים
    `,
        info: `
    במסגרת שירות זה אנו בוחנים את מכלול הנכסים בהיבטי מיסוי ומציגים דרכים לחיסכון במיסוי בצומת פרישה ועל השקעות אחרות.
    `,
    },

    {
        id: 4,
        name: "ביטוח",
        avatar: moneyImage,
        short: `
    בחינת הצורך הביטוחי אל מול מצב קיים וייעוץ על שינויים על פי צורך 
    `,
        info: `
      במסגרת שירות זה אנו בוחנים את הצרכים הביטוחיים של הלקוחות למול
      תיק הביטוח הקיים שלהם ומציגים להם שינויים בתיק הביטוח כך
      שיתאימו לצרכים שלהם וייצרו להם חיסכון בתשלומי הפרמיה השוטפים.
    `,
    },

    {
        id: 5,
        name: "הלוואות ומימון",
        avatar: accountingImage,
        short: `
    מיצוי פוטנציאל לאשראי בתנאים הטובים בשוק מנכסים פיננסים ופנסיוניים הקיימים
    `,
        info: `
    במסגרת שירות זה אנו בוחנים את האפשרות לקבלת אשראי בתנאים הטובים בשוק  לטובת צרכים שונים כגון : מיחזור משכנתא, רכישת דירה , הלוואה לכל מטרה , מינוף השקעות ועוד.
    `,
    },

    {
        id: 6,
        name: "העברת נכסים בין דורית",
        avatar: emailImage,
        short: `
    מגוון פתרונות בהעברת נכסים בין דורית.
    `,
        info: `
    מגוון פתרונות בתחום של העברה בין דורית לרבות חיסכון במס , וליווי במימוש נכסים בעת פטירה.
    `,
    },
]

export default services